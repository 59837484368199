<template>
    <div>
      <vs-popup class="holamundo" :title="$t('venueSettlements.settlementDetails')" :active.sync="isActive" @close="closePopup">
        <div v-if="currentSettlement">

          <div class="container mb-5">
            <div class="vx-row w-full sm:flex flex">
                <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                    <h3 class="opacity-75 text-lg lg:text-2xl">{{$t('venueSettlements.settlementAmount')}}</h3>
                    <h3 class="text-lg lg:text-2xl">{{currentSettlement.total_amount ? currentSettlement.total_amount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h3>
                </div>
            </div>
            <div class="flex space-x-2">
              <span class="text-lg text-black">{{$t('venueSettlements.completed_bookings_count')}}:</span>
              <span>{{ currentSettlement.completed_bookings_count }}</span>
            </div>
            <div class="flex space-x-2">
              <span class="text-lg text-black">{{$t('venueSettlements.total_completed_final_price')}}:</span>
              <span>{{ currentSettlement.total_completed_final_price }}</span>
            </div>
            <div class="flex space-x-2">
              <span class="text-lg text-black">{{$t('venueSettlements.total_paid_at_place')}}:</span>
              <span>{{ currentSettlement.total_paid_at_place }}</span>
            </div>
            <div class="flex space-x-2">
              <span class="text-lg text-black">{{$t('venueSettlements.total_paid_at_eventful')}}:</span>
              <span>{{ currentSettlement.total_paid_at_eventful }}</span>
            </div>
            <div class="flex space-x-2">
              <span class="text-lg text-black">{{$t('venueSettlements.total_paid_amount')}}:</span>
              <span>{{ currentSettlement.total_paid_amount }}</span>
            </div>
          </div>

          <span class="text-xl">{{ $t('venueSettlements.bookingsInSuttlement') }}</span>
          <div v-if="isLoaded">

            <vs-table
              class="mb-5"
              :sst="true"
              ref="involvedBookingsTable"
              @change-page="handleChangePage"
              pagination
              :total="parseInt(tableConfig.total)"
              :max-items="parseInt(tableConfig.perPage)"
              :data="involvedBookings"
            >
  
              <template  slot="thead">
                <template>
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.bookingNumber') }}</p>
                  </vs-th>
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.bookingDate') }}</p>
                  </vs-th>
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.eventDate') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.state') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.customerName') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.totalPrice') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.paidViaEventful') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.paidAtPlace') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.totalPaidAmount') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.cogs') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.eventfulCommissionPercentage') }}</p>
                  </vs-th>
  
                  <vs-th>
                    <p class="table-head font-medium truncate">{{ $t('venueSettlements.booking.settlementAmount') }}</p>
                  </vs-th>
                </template>
              </template>
  
              <template slot-scope="{data}">
                <tbody>
                  <template>
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td>
                        <p class="product-name font-medium truncate">{{tr.booking_number}}</p>
                      </vs-td>
                      <vs-td>
                        <p class="booking-date truncate">{{moment(tr.created_at).format('yyyy-MM-DD')}}</p>
                      </vs-td>
                      <vs-td>
                        <p class="booking-date truncate">{{tr.start_date}}</p>
                      </vs-td>
                      <vs-td>
                        <vs-chip :color="tr.state.color">{{tr.state.display_name}}</vs-chip>
                      </vs-td>
                      <vs-td>
                        <p class="booking-date truncate">{{tr.customer_name}}</p>
                      </vs-td>
  
                      <vs-td>
                        {{tr.receipt.local_final_price_without_discount ? tr.receipt.local_final_price_without_discount.toLocaleString() : 0}}
                      </vs-td>
  
                      <vs-td>
                        {{tr.receipt.total_paid_amount_at_qosoor ? tr.receipt.total_paid_amount_at_qosoor.toLocaleString() : 0}}
                      </vs-td>
  
                      <vs-td>
                        {{tr.receipt.paid_at_place_amount ? tr.receipt.paid_at_place_amount.toLocaleString() : 0}}
                      </vs-td>
  
                      <vs-td>
                        {{tr.receipt.paid_amount ? tr.receipt.paid_amount.toLocaleString() : 0}}
                      </vs-td>
  
                      <vs-td>
                        {{tr.receipt.cost_of_goods_sold ? tr.receipt.cost_of_goods_sold.toLocaleString() : 0}}
                      </vs-td>
  
                      <vs-td>
                        {{ tr.receipt.final_percentage_qosoor_commission }}%
                      </vs-td>
  
                       <vs-td>
                        {{ tr.receipt.settlement_amount }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </tbody>
              </template>
            </vs-table>
          </div>
          <div v-else>
            <div class="w-full flex justify-center">
              <Loading />
            </div>
          </div>
          <div class="flex flex-wrap">
            <vs-button class="mx-1" type="border" color="success" @click.stop="exportToExcel(currentSettlement)">
              {{ $t('venueSettlements.exportBookings') }}
            </vs-button>
            <vs-button v-if="['settled'].includes(currentSettlement.status)" class="mx-1" type="border" color="#6e6e6e" @click.stop="goToLink(currentSettlement.last_receipt_document_url)" >
              {{ $t('venueSettlements.viewReceipt') }}
            </vs-button>
          </div>
        </div>
      </vs-popup>
  
    </div>
  </template>
  
  <script>
  import moment from 'moment';
  import axios from '@/axios';
  import Loading from '@/views/components/qosoor-components/Loading/Loading.vue'

  export default {
    name: 'settlement-details',
    components: {
      Loading
    },
    props: {
        settlementDetailsActive: {
            type: Boolean,
            default: false
        },
        currentSettlement: {
            type: Object,
            defult: null
        },
    },
    data () {
      return {
        moment: moment,
        tableConfig: {
          total: 0,
          perPage: 100,
          currentPage: 1,
        },
      }
    },
    computed: {
      isActive: {
        get() {
          return this.settlementDetailsActive
        },
        set(newState) {
          return newState
        }
      },
      involvedBookings () {
        return this.$store.state.settlements.involvedBookings;
      },
      currentVenueId () {
        return this.$store.getters.currentVenueId
      },
      currentVenue () {
        return this.$store.getters.currentVenue? JSON.parse(this.$store.getters.currentVenue): {};
      },
      baseURL() {
        return process.env.VUE_APP_BASE_URL;
      },
      isLoaded() {
        return this.$store.state.settlements.involvedBookingsLoaded;
      }
    },
    created () {
    },
    methods: {
      closePopup() {
        this.$store.commit('settlements/SET_INVOLVED_BOOKINGS', []);
        this.$store.commit('settlements/SET_INVOLVED_BOOKINGS_LOADED', false)
        this.$emit('update:settlementDetailsActive', false);
      },
      handleChangePage(page) {
        this.tableConfig.currentPage = page;
        this.getSettlements(page);
      },
      exportToExcel(settlement) {
        const FileDownload = require('js-file-download');
        axios({
          url: `${this.baseURL}/venues/${this.currentVenueId}/settlements/${settlement.id}/export`,
          method: 'get',
          responseType: 'blob',
          headers: {
            'request-source': 'agent',
            "Authorization": "Bearer " + localStorage.getItem("accessToken"),
          }
        }).then((response) => {
            FileDownload(response.data, 'report.xlsx');
        });
      },
      goToLink(url) {
        window.open(url, '_blank')
      }
    }
  
  }
  </script>
  
  <style>
  
  </style>
  