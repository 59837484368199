<template>
  <div>

    <!-- SETTLEMENT DETAILS POPUP -->
    <settlement-details-popup :settlementDetailsActive.sync="settlementDetailsActive" :currentSettlement.sync="currentSettlement" />

    <vs-table
      class="mb-5"
      :sst="true"
      ref="SettlementsTable"
      @change-page="handleChangePage"
      v-model="selected"
      pagination
      :total="parseInt(tableConfig.total)"
      :max-items="parseInt(tableConfig.perPage)"
      :data="settlements"
      @selected="showData"
      stripe
    >

    <template  slot="thead">
        <template>
            <vs-th>
              <p class="table-head font-medium truncate">{{ $t('venueSettlements.title') }}</p>
            </vs-th>
            <vs-th>
              <p class="table-head font-medium truncate">{{ $t('venueSettlements.startDate') }}</p>
            </vs-th>
            <vs-th>
              <p class="table-head font-medium truncate">{{ $t('venueSettlements.endDate') }}</p>
            </vs-th>
            <vs-th>
              <p class="table-head font-medium truncate">{{ $t('venueSettlements.status') }}</p>
            </vs-th>
            <vs-th>
              <p class="table-head font-medium truncate">{{ $t('venueSettlements.bookingsCount') }}</p>
            </vs-th>
            <vs-th>
              <p class="table-head font-medium truncate">{{ $t('venueSettlements.settlementAmount') }}</p>
            </vs-th>
            <vs-th>
              <p class="table-head font-medium truncate">{{ $t('venueSettlements.month') }}</p>
            </vs-th>
            <vs-th></vs-th>
        </template>
    </template>

    <template slot-scope="{data}">
      <tbody>
        <template>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">{{tr.title}}</p>
              </vs-td>
              <vs-td>
                <p class="booking-date truncate">{{tr.start_date}}</p>
              </vs-td>
              <vs-td>
                <p class="booking-date truncate">{{tr.end_date}}</p>
              </vs-td>
              <vs-td>
                <!-- <div style="position:relative; z-index: 99999;">
                  <vs-tooltip text="tooltipText">
                    <vs-button>Button</vs-button>
                  </vs-tooltip>
                </div> -->
                <div class="vx-row">
                  <vs-tooltip text="hello" vs-z-index="999999">
                    <vs-chip :color="tr.status_object.color" class="truncate">
                        {{$i18n.locale == 'en' ? tr.status_object.display_name : tr.status_object.display_name_ar}}
                      </vs-chip>
                  </vs-tooltip>
                </div>
              </vs-td>
              <vs-td>
                {{ tr.number_of_bookings }}
              </vs-td>
              <vs-td>
                {{ tr.total_amount }} {{ $t('SAR') }}
              </vs-td>
              <vs-td>
                <p class="booking-date truncate">{{ $t('months.'+tr.month_name)}}</p>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <span class="flex flex-row whitespace-no-wrap">
                  
                  <vs-button class="mx-1" type="border" color="success" @click.stop="exportToExcel(tr)">
                    {{ $t('venueSettlements.exportBookings') }}
                  </vs-button>
                  <vs-button v-if="['settled'].includes(tr.status) && tr.last_receipt_document_url" class="mx-1" type="border" color="#6e6e6e" @click.stop="goToLink(tr.last_receipt_document_url)" >
                    {{ $t('venueSettlements.viewReceipt') }}
                  </vs-button>

                  <vs-button v-if="['waiting_agent_confirmation'].includes(tr.status)" class="mx-1 action-btn" @click.stop="takeActionPrompt(tr, 'approved')"  color="success">
                    {{ $t('venueSettlements.confirmation.approvedBtn') }}
                  </vs-button>
                  <vs-button v-if="['waiting_agent_confirmation'].includes(tr.status)"  class="mx-1 action-btn" type="border" @click.stop="takeActionPrompt(tr, 'rejected')"  color="danger">
                    {{ $t('venueSettlements.confirmation.rejectedBtn') }}
                  </vs-button>

                  <vs-button v-if="['settled'].includes(tr.status) " v-for="attachment in tr.attachments" class="mx-1" type="border" color="#7d34eb" @click.stop="goToLink(attachment)" >
                    {{ $t('venueSettlements.transformationReceipt') }}
                  </vs-button>
                </span>
              </vs-td>

            </vs-tr>
        </template>
      </tbody>
    </template>
    </vs-table>

    <vs-prompt @accept="takeAction" :active.sync="settlementConfirmation.isActive" :title="$t('venueSettlements.confirmation.title')" :accept-text="$t('venueSettlements.confirmation.' + settlementConfirmation.action + 'Btn')" :color="settlementConfirmation.action == 'approved' ? 'success' : 'danger'">
      <p>{{ $t('venueSettlements.confirmation.' + settlementConfirmation.action + 'Details') }}</p>
      <vs-textarea v-if="settlementConfirmation.action == 'rejected'" :label="$t('venueSettlements.confirmation.rejectionReason')" v-model="settlementConfirmation.rejection_reason" :placeholder="$t('venueSettlements.confirmation.rejectedDetailsPlaceholder')" />
    </vs-prompt>
  </div>
</template>

<script>
import SettlementDetailsPopup from '@/views/components/qosoor-components/VenueSettlements/SettlementDetailsPopup.vue';
import axios from '@/axios'
import moment from 'moment'

export default {
  components: {
    SettlementDetailsPopup
  },
  data () {
    return {
      moment: moment,
      selected: [],
      tableConfig: {
          total: 0,
          perPage: 10,
          currentPage: 1,
      },
      currentSettlement: null,
      settlementDetailsActive: false,
      settlementConfirmation: {
        isActive: false,
        action: null,
        rejection_reason: null
      }
    }
  },
  computed: {
    settlements () {
      return this.$store.state.settlements.settlements;
    },
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    baseURL() {
      return process.env.VUE_APP_BASE_URL;
    },
  },

  created () {
    this.getSettlements(1)
    window.addEventListener('changeLanguage', function(e){
      this.$store.dispatch('loader/loaderOn')
      Promise.all([ 
      ]).then(()=>{this.$store.dispatch('loader/loaderOff')}, this);
    }.bind(this));

  },

  methods: {
    async getSettlements(page = 1) {
      let query = {
        page: page,
        perPage: this.tableConfig.perPage
      }
      this.$store.dispatch('loader/loaderOn')

      const response = await this.$store.dispatch('settlements/getSettlements', {venueId : this.currentVenueId , params: query})
      this.tableConfig.total = response.data.meta.total

      this.$store.dispatch('loader/loaderOff')

      
    },
    handleChangePage(page) {
      this.tableConfig.currentPage = page;
      this.getSettlements(page);
    },
    showData (data) {
      this.$store.dispatch('settlements/getInvolvedBookings', {settlementId: data.id, venueId : this.currentVenueId})
      this.settlementDetailsActive = true;
      this.currentSettlement = data;
    },
    takeActionPrompt(settlement, action) {
      this.currentSettlement = settlement;
      this.settlementConfirmation.action = action;
      this.settlementConfirmation.isActive = true;
    },
    takeAction() {
      this.$store.dispatch('settlements/submitAnswer', {settlementId: this.currentSettlement.id, venueId : this.currentVenueId , params: this.settlementConfirmation})
      .then((response) => {
        this.$vs.notify({
          color:'success',
          title: this.$t('venueSettlements.confirmation.suttlementUpdatedTitle'), 
          text: this.$t('venueSettlements.confirmation.suttlementUpdated')
        })
      })
    },
    exportToExcel(settlement) {
      const FileDownload = require('js-file-download');
      axios({
        url: `${this.baseURL}/venues/${this.currentVenueId}/settlements/${settlement.id}/export`,
        method: 'get',
        responseType: 'blob',
        headers: {
          'request-source': 'agent',
          "Authorization": "Bearer " + localStorage.getItem("accessToken"),
        }
      }).then((response) => {
          FileDownload(response.data, 'report.xlsx');
      });
    },
    goToLink(url) {
      window.open(url, '_blank')
    }
  },
  watch: {
    
  }
}
</script>

<style>


.vs-tooltip {
  z-index: 99999 !important;
}

</style>
